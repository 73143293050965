/* adapted from https://github.com/DimitryDushkin/sliding-pane */

.slide-pane {
  overflow: hidden;
  box-shadow: 0 8px 8px rgba(0,0,0,0.5);
}

.slide-pane .slide-pane-header {
  background: white;
  position: fixed;
  top: 80px;
  bottom: 0px;
  left: 100%;
  right: 0px;
  transition: 0.5s;
}
.slide-pane-header h2 {
  transform: translateX(-50%);
}
.slide-pane .slide-pane-open {
  overflow: auto;
  transition: 0.5s;
  left: 100px;
  z-index: 1;
}

.slide-pane .slide-pane-arrow {
  font-size: 30px;
  cursor: pointer;
}

.slide-pane .slide-pane-arrow-container {
  background: #E8E8E8;
  display: flex;
}

.slide-pane .slide-pane-content {
  padding-top: 15px;
  padding-bottom: 100px;
  overflow: scroll;
}

.slide-pane-backdrop {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: black;
  opacity: 0.5;
  pointer-events: none;
}
