@use 'variables';
$gl-blue: variables.$gl-blue;
$gl-green: variables.$gl-green;
$gl-green-dark: variables.$gl-green-dark;
$gl-coral: variables.$gl-coral;
$gl-gray: variables.$gl-gray;
$enable-negative-margins: true;
$body-color: #212549;
$list-group-item-padding-x: 1.5rem;
$list-group-item-padding-y: 0.75rem;
$list-group-item-border-width: 1px;
$table-striped-bg: rgba(207, 229, 252, 0.25);
$table-hover-bg: rgba(204, 231, 255, 0.60);
$list-group-item-padding-x: 1.5rem;
$list-group-item-padding-y: 0.75rem;
$list-group-item-border-width: 1px;
$table-cell-padding-y: 0.5rem;
$table-border-width: 0;
$border-color: #d3d8dd;
$btn-padding-y: 0.3rem;

$theme-colors: (
  "primary":    $gl-blue,
  "secondary":  $gl-gray,
  "success":    $gl-green-dark,
  "info":       $gl-blue,
  "warning":    rgb(255,193,7),
  "danger":     $gl-coral,
  "light":      #f8f9fa,
  "dark":       #212529
);
@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap');
