@use "variables";
$gl-green: variables.$gl-green;
$gl-gray: variables.$gl-gray;

#home {
	.h1, h2, h3, h4 {
		font-weight: 600;
	}
	.home-circle {
		display: inline;
		margin: 0 auto;
		height: 100%;
		width: auto;
	}

	.circle-cropper {
		width: auto;
		max-width: 80vh; 
		max-height: 50vw;
		height: 80vh;
		position: relative;
		overflow: hidden;
		border-radius: 50%;
	}

	@media (max-width: 768px) {
		.circle-cropper {
			width: 60vw; 
			max-height: 70vh;
			height: 60vw;		
		}
	}
	
	@media (max-width: 480px) {
		.home-title-image {
			padding-top: 20px;
			padding-bottom: 80px;
			min-height: 700px;
			background-size: cover;
			background-position: 70% 0%;
		}
		.circle-cropper {
			width: 90vw; 
			max-height: 70vh;
			height: 90vw;		
		}
	}

	@media (min-width: 1600px)
	{
		.home-title-image
		{
			padding-top: 20px;
			padding-bottom: 80px;
			min-height: 760px;
			background-size: cover;
			background-position: center top;
		}
	}

	.home-icon-title
	{
		padding-top: 20px;
	}

	.home-row-image
	{
		width: 100%;
		z-index:-1;
		background-size: cover;
	}

	.home-row-image-container
	{
		height: 100%;
	}

	.check-mark-list
	{
		text-indent: 2px;
		list-style: none;
		list-style-position: outside;
		font-size: 20px;
	}

	.check-mark-list li {
		margin-bottom: 1.5rem;
	}

	.check-mark-list li::before
	{
		font-family: FontAwesome;
		padding-right: 7px;
		padding-left: 15px;
		font-size: 25px;
		
		content: "\f00c"; /* fa-check (hex value)*/
		color: $gl-green;
		
		margin-left: -50px;
		margin-right: .100em;
	}

	.home-row
	{
		margin-top: 60px;
	}

	/* Home Page changes - 03/20/20 */

	.link-button-h
	{
		font-size: 28px;
			color: white;
		text-decoration: underline;
	}

	.link-button-body
	{
		font-size: 32px;
			color: #0055BB;
		text-decoration: underline;
		padding-top: 15px;
	}

	.link-button-h:hover
	{
		text-decoration: none;
			color: white;
			font-weight: 700;
			font-size: 30px;
		transition-property: font-size, text-decoration, background-image;
		border-radius: 25px;
			padding: 15px;
			background-image: radial-gradient(rgba(155,155,155,1) 0%, rgba(255,255,255,0.3) 30%, rgba(255,255,255,0) 60%);
	}

	.link-button-body:hover
	{
		text-decoration: none;
			color: #0055BB;
			font-weight: 700;
			transition-property: font-size, text-decoration;
	}

	.home-title-main
	{
		color: white;
		padding-bottom: 0px;
		font-size: 48px;
		margin-top: 40px;
		font-weight: 700;
	}

	.home-subtitle-main
	{
		color: white;
		padding-bottom: 24px;
		font-size: 32px;
		font-weight: 600;
	}
	.header-logo {
		max-width: 110px;
	}
	.icon-text
	{
		margin-top: 15px;
		font-size: 18px;
	}

	.arrow-icon
	{
		font-size: 20px;
		padding-left: 5px;
	}

	.arrow-icon-header
	{
		font-size: 20px;
	}

	.alt-background-color
	{
		background-color: #f8f8f8;
	}

	.testimonial-statement
	{
		font-size: 24px;
		font-weight: 600;
		font-style: italic;
		text-align: left;
		padding-bottom: 32px;
	}

	.testimonial-attribution
	{
		margin-top: 20px;
		font-size: 24px;
		text-align: center;
	}

	@media (min-width: 992px)
	{
		.testimonial-attribution
		{
			margin-top: 20px;
			font-size: 24px;
			text-align: left;
			float: left;
		}
	}

	.testimonials-section
	{
		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 25px;
	}

	.testimonial-image
	{
		width: auto;
		max-height: 250px;
	}

	@media (min-width: 992px)
	{
		.testimonial-image
		{
			width: auto;
			float: left;
			max-height: 250px;
		}
	}

	.section-title
	{
		font-size: 36px;
		font-weight: 700;
		margin-bottom: 25px;
	}

	.section-title-yt
	{
		font-size: 48px;
		font-weight: 700;
	}
	
	.carousel-caption
	{
		position: static;
	}

	.testimonial-link
	{
		float: left;
		padding-bottom: 20px;
		position: relative;
		z-index: 20;
	}

	.carousel-caption
	{
		background-color: #f8f8f8;
		color: black;
		padding: 20px 60px;
		margin-bottom: 15px;
	}

	@media (min-width: 992px)
	{
		.carousel-caption
		{
			background-color: #f8f8f8;
			color: black;
			padding: 20px 120px;
			margin-bottom: 15px;
		}
	}

	.testimonial-left
	{
		margin-bottom: 24px;
	}

	.navbar-default
	{
		max-width:100%;
	}

	/* modifications to fix main/body margin */

	body
	{
		max-width: 100%;
		overflow-x: hidden;
	}

	div#padding
	{
			padding-right: 0px !important;
			padding-left: 0px !important;
	}

	@media (min-width: 992px)
	{
			body
			{
			padding: 0 0px 10px 0px;
			max-width:100%;
			overflow-x: hidden;
			}
	}
	main {
			margin-top: -20px;
			margin-right: auto !important;
			margin-left: auto !important;
	}

	#page {
			padding-right: 0px !important;
			padding-left: 0px !important;
	}

	.content-section-g
	{
		background-color: #f8f8f8;
		width: 100%;
		margin-top: 50px;
	}

	.content-section {
		width: 90%;
		margin-bottom: 15vh;
	}

	.content-section-w
	{
			background-color: white;
			width: 100%;
		margin-top: 50px;
	}

	.content-section-w h3 {
		font-weight: 600;
	}

	.content-section-g img
	{
		height: 100%;
	}

	.section-pic-left {
		padding-left: 0%;
		padding-right: 0%;
		margin-left: 10%;
	}

	@media (min-width: 1200px)
	{
		.section-pic-left
		{
			padding-left: 0%;
			padding-right: 50px;
		}
	}

	.section-pic-right
	{
		padding-right: 0%;
			padding-left: 0%;
	}

	@media (min-width: 1200px)
	{
		.section-pic-right
		{
			padding-right: 0%;
			padding-left: 80px;
		}
	}

	.section-mid
	{
		padding-right: 5%;
		padding-left: 5%;
	}

	.section-text
	{
		padding-left: 40px;
	}

	#home-grade-graph
	{
		height: auto;
	}

	.youtube-section h2
	{
		font-size: 25px;
		padding-bottom: 8px;
	}

	.carousel-control
	{
		color: black !important;
		background-image: none !important;
		font-size: 30px;
	}

	.carousel-control:hover
	{
		color: black !important;
	}

	.alert-container
	{
		background-color: #0A49BB;
		padding-top: 10px;
		padding-bottom: 2px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.alert
	{
		margin-top: 25px;
	}
	.testimonial-item {
		max-width: 600px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		background-color: #fff;
		border: solid 1px rgba($gl-gray,0.5);
		border-radius: 15px;
}
.crop-wrapper {
		overflow-y: hidden;
}
.testimonial-item img {
		width: 7rem;
		height: 7rem;
		object-fit: cover;
		object-position: 50% 20%;
}
}
