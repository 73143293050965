@use 'variables';
$gl-gray: variables.$gl-gray;

#navbar {
    min-height: 75px;
    font-size: 14px;
    background-color: white;
    box-shadow: 0 0 12px 1px $gl-gray;

    @media only screen and (min-width: 992px) {

        #navbar-collapse {
            margin-right: 2px;
            margin-left : 2px;
        }
        /* add padding between navbar items */
        .navbar-nav > li {
            padding-left:10px;
            padding-right:10px;
        }
    }
    
    /* Bring the collapsed navbar's top line down to fit image */
    /* Bring navbar contents down to adjust for taller navbar */
    /* Navbar was expanded by 30px to accomodate image, so links are brought down by half that value to maintain centering */
    .collapse.navbar-collapse.in, .collapsing.navbar-collapse, nav .container-fluid {
        margin-top: 15px;
    }
    
    /* Make background slightly transparent */
    .navbar.navbar-default.navbar-fixed-top {
        background-color: rgba(244, 244, 244, 1);
    }
    
    /* Image needs to be fixed-size for absolute centering to work */
    .navbar-brand img {
        width: 94px;
        height: 60px;
    }
    
    /* Override default styling which puts edge of navbar off right edge of screen */
    /* Undo left button separation when in collapsed navbar */
    .navbar-right, .collapse.navbar-collapse.in .navbar-left .navbar-btn, .collapsing.navbar-collapse .navbar-left .navbar-btn {
        margin-right: 0px;
    }
    
    /* Separate left button(s) from other navbar links */
    .navbar-left .navbar-btn {
        margin-right: 20px;
    }
    
    /* Separate right button(s) from other navbar links */
    .navbar-right .navbar-btn {
        margin-left: 20px;
    }
    
    /* Undo right button separation when in collapsed navbar */
    .collapse.navbar-collapse.in .navbar-right .navbar-btn, .collapsing.navbar-collapse .navbar-right .navbar-btn {
        margin-left: 0px;
    }
    
    @media only screen and (max-width: 767px) {
        min-height: 60px;

        #page {
            margin-top: 60px;
        }

        /* Image needs to be fixed-size for absolute centering to work */
        .navbar-brand img {
            width: 74px;
            height: 47px;
        }
    
        .container-fluid {
            margin-top: 5px;
        }
    
        .collapse.navbar-collapse.in, .collapsing.navbar-collapse {
            margin-top: 5px;
        }
    }
}
