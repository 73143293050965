#student-roster {
	.blue-panel {
		color: #333;
		background-color: rgba(207, 229, 252, 0.4);
		line-height: 0.7rem;
		padding-bottom: 1em;
		padding-top: 1em;
		padding-right:0.9em;
		padding-left:0.9em;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		text-align: start;
		hr {
			margin-top: -3px;
			margin-right: -5px;
			margin-left:-5px;
		}
	}
	.license {
		height:20px;
		font-size: 13px;
		border: solid 1px !important;
		background-color: white default;
		border-radius: 12px;
		padding-right:10px;
		padding-left:10px;
		display: inline;
		margin-right: 10px;
	}
	.license.reading {
		color:rgba(99, 155, 248, 0.85);
	}
	.license.math {
		color:rgba(254, 136, 128, 0.85);
	}
	.license.archived {
		color: gray;
	}
	.license i {
		vertical-align: top;
	}
		/* GL table styles */
	.table > :not(caption) > * > * {
		padding: 1.0rem 0.5rem;
	}
	.table-striped > tbody > tr:nth-of-type(odd) > * {
		background-color: rgba(207, 229, 252, 0.13);
	}
	.table-striped tbody tr {
		position: relative; 
		text-align: center;
	}
	.table-striped tbody tr:hover {
		background-color: #CCE7FFaa;
	}
	.table-striped tbody tr:before {
		position: absolute;
		content: "";
		opacity: 0;
		width:100%;
		top: -3px;
		bottom: 0px;
		box-shadow: 0 1px 1px -1px rgba(23, 143, 255, 0.1), 0 0 3px -3px rgba(23, 143, 255, 0.3),
			0 1px 5px -4px rgba(23, 143, 255, 0.3);
		z-index: -1;
		animation: ripples 3s ease-out infinite;
		animation-play-state: paused;
		visibility: hidden;
		transition: 0.6s;
	}
	.table-striped tbody tr:hover:before {
		animation-play-state: running;
		opacity: 1;
		visibility: visible;
	}
	.active .page-item {
		background-color: #5271ff;
	}
	.page-item:not(.active) .page-item:not(.disabled) .page-link {
		color: #5271ff;
	}
	@keyframes ripples {
		to {
			box-shadow: 0 1px 1px -1px rgba(23, 143, 255, 0.1), 0 8px 3px rgba(23, 143, 255, 0),
				0 12px 5px rgba(23, 143, 255, 0);
		}
	}
	.tableSelect {
		width:100%;
	}
}
#dashboard {
 	.table-striped tbody tr {
		cursor: pointer;
		border-right: solid 1px rgba(0, 0, 0, 0.1); 
		border-left: solid 1px rgba(0, 0, 0, 0.1);
	}
 	.table-striped tr th {
		border-right: solid 1px rgba(0, 0, 0, 0.1); 
		border-left: solid 1px rgba(0, 0, 0, 0.1);
	}
}
.check-bg {
	padding-left: calc(18px + 1rem);

	input {
		width: 20px;
		height: 20px;
	}
	label {
		padding-left: 0.5rem;
	}
}
#archive-modal {
	.modal-body {
		font-size: 20px;
	}

	.archive-warning {
		background: rgba(220, 60, 60, 0.03);
		padding-left: 3rem;
		padding-right: 3rem;
		font-size: 15px;
		.fa-exclamation-circle {
			font-size: 40px;
		}
	}
}

.student-modal {
	.modal-header {
		padding:1.5rem;
		border-color:rgb(99, 155, 248) !important;
	}
	h2 {
		font-weight: 300;
	}
	font-size: 1.1rem;
}
/* this needed to be added when we upgraded bootstrap 5, 
so will likely delete it when react table has been upgraded
*/
.react-table .tableSelect input[type=checkbox] {
	border: solid gray 1px!important;
}
