
#whyitmatters-container
{
    background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)) , url("./boy-and-teacher.jpg");
}

#districts
{
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)) , url("./skyline.jpg");
}

#schools
{
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)) , url("./classroom.jpg");
}

#community
{
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)) , url("./community.jpg");
}

#longdistance
{
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)) , url("./long-distance-learning.jpg");
}