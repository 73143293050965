@use 'variables';
$gl-blue: variables.$gl-blue;

#probe {
	.key-box {
		width: 15px;
		height: 15px;
	}
	.timer {
		max-width: 500px;
	}
	@media only screen and (max-width: 1500px) {
		.h3 #probe-help-btn {
			display: none;
		}
	}
}
#probe-modal {
	max-height:70vh;
	max-width:100vw;
	.btn.round {
		border-radius: 20px;
	}
	.me-n3 {
		margin-right: -1rem;
	}
	.blue-box {
		background-color: #CFE5FC;
		border-radius: 15px;
	}
	input[type=checkbox] {
		width: 16px;
		height:16px;
		margin-right:10px !important;
		cursor: pointer;
		outline: solid 1px $gl-blue;
		outline-offset: -1px;
	}
	.form-check-label {
		margin-left:15px;
	}
}
#goal-page {
	background: linear-gradient(176.45deg, rgba(38, 234, 222, 0.5) 17.34%, rgba(20, 72, 207, 0.5) 77.72%);
	background-repeat: no-repeat;
	background-size: cover;
	.star {
		width: 32vh;
	}
	min-height: calc(100vh - 80px);
	.crab-goal {
		position: absolute;
		left: 30%; 
		bottom: 5%;
		width: 15%;
		max-width: 200px;
	}
	.dolphin-goal {
		position: absolute;
		right: 15%; 
		bottom: 4%;
		width: 38%;
		max-width: 400px;
	}
	.goal-card {
		margin-top: 13vh;
		background-color: rgba(255,255,255,0.75);
		max-width: 800px;
		z-index: 1;
	}
	.goal-bg {
		background: linear-gradient(176.45deg, rgba(38, 234, 222, 0.5) 17.34%, rgba(20, 72, 207, 0.5) 77.72%);
		background-image: url("../content/images/goal_bg.svg");
		background-repeat: no-repeat;
		background-size: cover;
		min-height: calc(100vh - 80px);
	}
	.confetti-center {
		position: absolute;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		left: 50%;
	}
	@media (max-width: 1200px) {
		background-image: url("../content/images/goal_bg.svg");
	}
	@media (min-width: 1201px) {
		background-image: url("../content/images/goal_bg_wide.svg");
	}
	@media (max-width: 992px) {
		.dolphin-goal {
			right: 6%;
		}
		.crab-goal {
			left: 15%;
		}
	}
	@media (max-width: 442px) {
		.dolphin-goal {
			right: 0;
			bottom: 11%;
			width: 60%;
		}
		.crab-goal {
			left: 15%;
			bottom: 5%;
			width: 25%;
		}
	}
}
.story-probe {
	#probe-contents {
		width: fit-content;
		margin: auto;
	}
}
.awareness-prep-button {
	position: absolute;
	top: 15%;
	right: 1%;
}

@media (max-width: 992px) {
	.awareness-prep-button {
		position: static;
		margin-top: 10px;
	}
}