@use 'variables';
$gl-blue: variables.$gl-blue;
$gl-green: variables.$gl-green;
$gl-coral: variables.$gl-coral;
$gl-gray: variables.$gl-gray;
$font-color-reg: #524D74;
$font-color-light: #5C5C5C;
.font-light {
	color: $font-color-light;
}
.gl-green {
	color: $gl-green;
}
.gl-coral {
	color: $gl-coral;
}
.bg-light-red {
	background-color: #FFE7E5;
}
.bg-light-blue {
	background-color: #2a75f630;
}
.vertical-align {
	display: flex;
	align-items: center;
	justify-content: center;
}
.move-forward {
	z-index: 2;
}
.bg-blue {
	color: white;
	background-color: rgba($gl-blue,0.95);
}
.error {
	color: $gl-coral;
}
.warning {
	color: #8a6d3b;
}
.success {
	color: #3c763d;
}
.medium-small {
	font-size: 14px;
}
.sub-header {
	flex-wrap: nowrap;
	border-bottom: 1px solid rgba($gl-gray,0.3);
	border-top: 1px solid rgba($gl-gray,0.4);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
	padding-bottom: 0.7rem;
	padding-top: 0.7rem;
}
.overlayLandingTitle {
	text-align: center;
	max-width: 575px;
	margin: auto;
	padding-bottom: 10px;
}
.customTextBox {
	border: solid 1px $gl-gray;
	border-radius: 10px;
	min-height:100px;
	cursor: text;
}
.cursor-pointer {
	cursor: pointer;
}
.fixed-bottom {
	position: fixed;
	bottom: 0;
}
@media (min-width: 1600px) {
	.col-xxxl-2 {
		width: 16%;
	}
}
.modal {
	.top-right {
		position: absolute;
		right:0;
		top:0;
	}
	.top-left {
		position: absolute;
		left:0;
		top:0;
	}
	.left-mid {
		position: absolute;
		left:0;
		top:15%;
	}
	.right-mid {
		position: absolute;
		right:0;
		top:15%;
	}
	.bottom-right {
		position: absolute;
		bottom:0;
		right:0;
	}
	.bottom {
		position: absolute;
		bottom:0;
		margin-bottom: auto;
	}
}
.word-nowrap {
	white-space: nowrap;
}
.section-break {
	margin-top: 8rem;
}
.icon-block a {
	text-decoration: none;
	color: #212529;
}
.icon-block .item .icon {
	height: 9rem;
}
.icon-block .item {
	max-width: 20rem;
	z-index: 2;
}
.blob {
	min-width:34%;
	z-index: 0;
	pointer-events: none;
}
.blob.right {
	position: absolute;
	right: 0;
}
.font-md {
	font-size: 15px;
}
.flex-center {
	justify-content: center;
	align-items: center;
}
.selected-dropdown {
	background-color: rgba($gl-blue,0.1);	
}
@media (min-width: 1500px) {
		.d-xxl-none {
			display: none !important;
		}
		.d-xxl-block {
			display: block !important;
		}
}
