.awareness-columns .response {
    width: 80% !important;
    display: inline-block;
    line-height: 1.6em;
    font-size: 25px; /* make adjustable by font size */
}


.awareness-columns .response-text {
    width: 80% !important;
    display: inline-block;
}

.response.error {
    color: #FFFFFF;
    background-color: #FD5E53 !important;
}
