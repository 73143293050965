#dashboard {

    /* Hide random-start carousels on load */
    /* Carousel will be faded in by JavaScript after a random starting slide has been chosen */
    .carousel.random {
        opacity: 0;
    }

    /* Matching rounded corners on carousel inner content, captions, and control buttons */
    .carousel-inner.rounded,
    .carousel-caption,
    .carousel-control.rounded {
        border-radius: 10px;
    }

    .carousel-caption {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 35px;

        margin-top: 15px;

        background-color: rgba(0, 0, 0, 0.85);

        position: relative;
        left: 0px;
        bottom: 0px;
    }

    .carousel-control {
        width: 10%;

        transition-property: opacity;
    }

    /* Carousel with indicators fixed above content */
    .carousel-indicators.indicators-top {
        top: -30px;
        bottom: initial;
    }

    .carousel.indicators-top {
        margin-top: 40px;
    }

    /* Indicators of current carousel slide */
    .carousel-indicators li {
        border-style: solid;
        border-color: #000000;
        border-width: 2px;
    }

    .carousel-indicators li {
        background-color: rgba(0, 0, 0, 0.1);

        transition-property: background-color, height, width, margin-top, margin-left, margin-right;
    }

    .carousel-indicators .active {
        background-color: #FFFFFF;
    }

    .carousel-indicators li {
        margin-right: 5px;
        margin-left: 5px;
    }

    /* Decrease margin when an indicator is active so that the indicators don't move with a slide change */
    .carousel-indicators li.active {
        margin-right: 4px;
        margin-left: 4px;
    }

    /* Home page carousel images are only 1600 x 800 */
    .carousel#home-carousel {
        max-width: 1600px;
    }

    .share-title {
        padding: 5px;
        padding-left: 25px;
        padding-right: 25px;
        border-style: solid;
        border-color: #0055BB;
        border-width: 3px;
        border-radius: 15px;
    }

    .share-title-container {
        margin-top: 90px;
        margin-bottom: 20px;
    }

}